import React from 'react';

import Layout from '../components/layout';
import Contact from '../components/contact';
import SEO from '../components/seo';

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact Dewebgineer"
        description="Send Dewebgineer a message about your web or mobile application needs or idea and we will let you know how we can help."
      />
      <section className="mb-6 py-16 w-full flex-1">
        <div className="max-w-screen-lg mx-auto">
          <div className="px-4 text-center mb-10 max-w-screen-md mx-auto md:px-16">
            <h1 className="text-3xl font-bold text-royal-blue mb-3">
              Want to Start a Project?
            </h1>
            <p className="text-lg mb-3">
              Complete the form below with details about your project and
              let&apos;s get the process started!
            </p>
            <div>
              <h2 className="text-lg font-bold">Reach out</h2>
              <a
                href="mailto:info@dewebgineer.com"
                className="text-indigo-dye hover:text-celadon-blue"
              >
                info@dewebgineer.com
              </a>
            </div>
          </div>
          <div className="max-w-screen-md mx-auto">
            <div className="md:px-16">
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
